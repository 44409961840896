/*
 * File: global.service.ts
 * Project: UTU - A collaborative agency
 * Created: Thursday, October 19th 2023, 4:42:53 am
 * Last Modified: Thursday, June 13th 2024, 3:56:09 pm
 * Copyright © 2019 UTU, Lda
 */

import { Injectable } from "@angular/core";
import { Http } from "./http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  url = environment.url;
  user;

  chatContainer;

  loading;

  status;

  constructor(private http: Http) {
    this.getStatus();
  }

  getStatus() {
    this.http
      .post(this.url + "/find/status", null)
      .toPromise()
      .then((result) => (this.status = result))
      .catch((result) => Promise.reject(result))
      .finally();
  }

  getCountries() {
    return this.http
      .get(this.url + "/countries")
      .toPromise()
      .then((result) => (this.status = result))
      .catch((result) => Promise.reject(result))
      .finally();
  }

  getStates(countryId) {
    return this.http
      .get(this.url + "/state?country_id=" + countryId)
      .toPromise()
      .then((result) => (this.status = result))
      .catch((result) => Promise.reject(result))
      .finally();
  }

  getCities(stateId) {
    return this.http
      .get(this.url + "/cities?state_id=" + stateId)
      .toPromise()
      .then((result) => (this.status = result))
      .catch((result) => Promise.reject(result))
      .finally();
  }

  updateAccount(user) {
    return this.http
      .post(this.url + "/user/update", user)
      .toPromise()
      .then((result) => (this.status = result))
      .catch((result) => Promise.reject(result))
      .finally();
  }

  calculateTotals(itens, groups?) {
    let total = 0;
    let totalPeso = 0;
    let totalValor = 0;

    if (itens.length > 0) {
      itens.forEach((element) => {
        total += +element.tamanho?.original;
        totalPeso += +element.peso;
        totalValor += +element.price;
      });
    }

    if (groups != null && groups.length > 0) {
      groups.forEach((element) => {
        total += +element.tamanho?.original;
        totalPeso += +element.peso.original;
        totalValor += +element.value;
      });
    }

    return {
      totalVolume: total,
      totalPeso,
      totalValor,
    };
  }

  compareObjects(obj1: any, obj2: any) {
    // tslint:disable-next-line:triple-equals
    return obj1 && obj2 ? obj1.id == obj2.id : obj1 == obj2;
  }

  getConteiners() {
    return this.http
      .get(this.url + "/reports/conteiners")
      .toPromise()
      .then((result) => result)
      .catch((result) => Promise.reject(result));
  }

  getAddress(addresses, type) {
    for (let address of addresses) {
      if (address.type === type) {
        return address;
      }
    }
  }

  calculateUpFront(total: number, installments: any[]): number {
    const installmentsTotal = installments.reduce(
      (previous, current) => +current.value + +previous,
      ""
    );
    const upFront = total - installmentsTotal;

    return upFront;
  }

  /*  checkOnline() {
     return this.http.get(this.url + "/checkOnline")
       .toPromise()
       .then(result => result)
       .catch(result => Promise.reject(result));
   } */
}
